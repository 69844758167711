export const GroupEntityKinds = [
  'group',
  'GithubTeam',
  'MsgraphGroup',
  'WorkdayGroup',
  'ExtendedGroup',
];

export const UserEntityKinds = [
  'user',
  'GithubUser',
  'MsgraphUser',
  'WorkdayUser',
  'ExtendedUser',
];
