import { Typography, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { isNil } from 'lodash';
import React from 'react';

import * as Constants from '../../constants';
import { ServiceAlignmentHelpDialog } from '../../MissingDialog';
import { LinkToWebsite, NotFound } from '../Common/common';

const useStyles = makeStyles({
  displayLinebreak: {
    whiteSpace: 'pre-line',
  },
});

const ALERT_MESSAGE = `Should be defined if lifecycle is production.
\n0 - Services and/or systems that have a direct impact on the Organisation's Core Mission and/or its Revenue.
\n1 - Services that when down cause a degraded customer experience, but do not prevent the customer from interacting with celonis platform
\n2 - Services that when down have almost unnoticeable customer or business impact.`;

const DESCRIPTION =
  'Classification of the criticality of a group of services based on their characteristics & unavailability Impact. Values can be any of [ "0" | "1" | "2" ]';

function TierAlert() {
  const classes = useStyles();

  return (
    <Alert severity="info">
      <AlertTitle>Tier of a service</AlertTitle>
      <div className={classes.displayLinebreak}>{ALERT_MESSAGE}</div>
    </Alert>
  );
}

type TierDataProps = Readonly<{
  tier: string | undefined;
  lifecycle: string | undefined;
}>;

export function TierData({
  tier,
  lifecycle,
}: TierDataProps): React.JSX.Element {
  if (lifecycle !== 'production') {
    const tableVal = isNil(tier) ? 'Not applicable' : tier;

    return (
      <NotFound
        message={DESCRIPTION}
        field="spec.tier"
        fieldTitle="Tier"
        value={Constants.MISSING_TIER_NOT_PRODUCTION}
        tableValue={tableVal}
        color="textSecondary"
        children={<TierAlert />}
      />
    );
  }

  const isInvalidTierType = typeof tier !== 'string';

  if (isNil(tier) || isInvalidTierType) {
    return (
      <NotFound
        message={DESCRIPTION}
        field="spec.tier"
        fieldTitle="Tier"
        value={
          <LinkToWebsite
            url={Constants.LINK_TO_CATALOG_DOCS}
            color="secondary"
            message={
              isInvalidTierType
                ? Constants.INVALID_TIER_TYPE
                : Constants.MISSING_TIER_IN_PRODUCTION
            }
          />
        }
        tableValue={isInvalidTierType ? 'Invalid' : 'Required'}
        color="secondary"
        children={<TierAlert />}
      />
    );
  }

  return (
    <Typography variant="body2">
      {tier}
      <ServiceAlignmentHelpDialog
        fieldTitle="Tier"
        message={DESCRIPTION}
        field="spec.tier"
        value={tier}
      >
        <TierAlert />
      </ServiceAlignmentHelpDialog>
    </Typography>
  );
}
