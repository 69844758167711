import { DATADOG_ALIASES_ANNOTATION } from '@celonis/backstage-plugin-celodrome-common/annotations';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import ListItemButton from '@mui/material/ListItemButton';
import React from 'react';

import * as Constants from '../../constants';
import { ServiceAlignmentHelpDialog } from '../../MissingDialog';

const useStyles = makeStyles({
  list: {
    maxHeight: 180,
    width: 200,
    overflow: 'auto',
    scrollbarWidth: 'thin',
  },
});

export function DatadogAliases({
  datadogAliasesString,
}: {
  datadogAliasesString: string;
}): React.ReactNode {
  const classes = useStyles();

  const aliases = datadogAliasesString
    .split(',')
    .filter(Boolean)
    .map(alias => alias.trim());

  return (
    <Box display="flex" alignItems="flex-start">
      <List className={classes.list}>
        {aliases.map(alias => (
          <ListItem key={alias} disableGutters dense divider>
            <ListItemButton
              disableGutters
              href={`${Constants.DATADOG_URL}${alias}`}
              target="_blank"
            >
              <ListItemText
                primary={alias}
                primaryTypographyProps={{
                  variant: 'body2',
                  color: 'primary',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <ServiceAlignmentHelpDialog
        message="Links to additional Datadog aliases associated with this service"
        field={`metadata.annotation.${DATADOG_ALIASES_ANNOTATION}`}
        value={datadogAliasesString}
        fieldTitle="Datadog Service Aliases"
      />
    </Box>
  );
}
