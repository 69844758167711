export const ARGOCD_ANNOTATION_APP_SELECTOR = 'argocd/app-selector';
export const GITHUB_PROJECT_SLUG_ANNOTATION = 'github.com/project-slug';
export const CFG_IBC_ANNOTATION = 'cd.celonis.dev/cfg-ibc-folder';
export const DATADOG_SERVICE_ANNOTATION =
  'datadog.celonis.dev/service-catalog-name';
export const FEDRAMP_ANNOTATION = 'cd.celonis.dev/fedramp';

export const MISSING_GITHUB_SLUG_MESSAGE =
  'Missing annotation for Github Repository.';
export const MISSING_ARGOCD_ANNOTATION_MESSAGE =
  'Missing annotation for ArgoCD. Please add it with the format: app=<app-selector>.';
export const DATADOG_INFO_MESSAGE =
  'By default, the datadog service related to this entity needs to be the same as the metadata.name. Otherwise the override annotation needs to be setup';

export const MISSING_CFGIBC_AND_ARGO_ANNOTATIONS =
  'Missing annotation for ArgoCD and CFG_IBC folder. CFG_IBC annotation is only required if your folder there doesnt match the value for your ArgoCD App Selector.';

export const MISSING_CFGIBC_ANNOTATION_ARGO_USED_MESSAGE =
  "ArgoCD annotation value is used. If your cfg-ibc folder doesn't match the ArgoCD application selector, add the cfg-ibc annotation to your entity.";

export const ARGOCD_WRONG_FORMAT =
  'The annotation should have the format app=<app-selector>';

export const ARGOCD_URL = 'https://argocd.k8s.celonis.cloud/applications';
export const DATADOG_URL = 'https://celonis.datadoghq.com/apm/services/';
export const CFG_IBC_URL = 'https://github.com/celonis/cfg-ibc/tree/master/';

export const LINK_TO_CATALOG_DOCS =
  '/docs/default/component/backstage-documentation/3.-Advanced-topics/catalog-info-file/5-component-definition';

export const MISSING_TIER_NOT_PRODUCTION =
  'Tier is not required for non-production services.';
export const MISSING_TIER_IN_PRODUCTION =
  'Tier is required for production services. Follow this documentation to set the tier for your service.';
export const INVALID_TIER_TYPE = 'Tier should be a string.';

export const FEDRAMP_MODAL_MESSAGE =
  'FedRAMP is optional and should be only added if you are using the service in a FedRAMP compliant environment.';

export const NOT_DEPLOYED_IN_REALMS_MESSAGE =
  'Not applicable for entities that are not deployed independently in Realms';

export const NON_INDEPENDENT_REALM_TYPES = ['frontend'];
